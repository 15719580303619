import React from "react";
import "./CobertureS.css";
import { App } from "./Slider";

function Coberture() {
  return (
    <div className="Content-T" id="Modulos">
      <h3 className="TitleModules">Modulos disponibles</h3>
      <App className="slideS" />
    </div>
  );
}

export { Coberture };
