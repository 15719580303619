import "./VideoComponent.css";
import React from "react";
import Video from "../Videos/Coberture/Promotional.mp4";

function VideoComponent() {
  return (
    <section className="VideoContainer">
      <div className="miTexto">
        <h2 className="titleText">
          Gestionar clientes y servicios nunca había sido tan facil.
        </h2>
        <p className="subtitulo">Sistema en constante evolución.</p>
      </div>
      <div className="miVideo">
        <video width="100%" height="40%" controls loop>
          <source src={Video} type="video/mp4" />
          Tu navegador no soporta el elemento de video.
        </video>
        {/* <iframe
          width="800"
          height="515"
          id="videoDimensions"
          src="https://www.youtube.com/embed/0BywYe_iywA?si=QIVDi3GIR0en-Znb"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe> */}
      </div>
    </section>
  );
}

export { VideoComponent };
