import "./infoGeneral.css";
import React from "react";
import PCMaster from "../assets/PCMaster.png";
import UserData from "../assets/UserData.png";
import GraphImage from "../assets/GraphImage.png";
import CakeImage from "../assets/CakeImage.png";
import iconRubpi from "../assets/iconRubpi.svg";

function InfoGeneral() {
  return (
    <article className="containerGeneral">
      <div className="contenedorTexto">
        <div
          style={{
            maxWidth: "80%",
            textAlign: "justify",
            color: "#1976d2",
            fontWeight: 500,
          }}
        >
          <h2 className="textTitle">¿Que es?</h2>
          <p>
            Rubpi es un software de administración de ISP. Ofrece herramientas
            para la captación y gestión de usuarios, facturación,
            notificaciones, mensajería y monitoreo de dispositivos de red.
          </p>
          <img className="IconRubpi" src={iconRubpi} alt="RubpiIcon" />
        </div>
      </div>
      <div className="contenedorImagenes">
        <img className="illustrationHeader2" src={PCMaster} alt="Illutro" />
        <img className="illustrationHeader3" src={UserData} alt="Illutro" />
        <img className="illustrationHeader4" src={CakeImage} alt="Illutro" />
        <img className="illustrationHeader5" src={GraphImage} alt="Illutro" />
      </div>
    </article>
  );
}
export { InfoGeneral };
