import "./Mission&Vision.css";
import React from "react";
import TicketAsset from "../assets/TicketAsset.png";
import ChatAsset from "../assets/ChatAsset.png";
import ClientAsset from "../assets/ClientsAsset.png";
import { GoGoal } from "react-icons/go";
import { FaRegLightbulb } from "react-icons/fa";

function MissionVision() {
  return (
    <article className="containerMissionVision">
      <div className="contenedorMV2">
        <img className="illustrationMV2" src={ClientAsset} alt="Illutro" />
        <img className="illustrationMV3" src={ChatAsset} alt="Illutro" />
        <img className="illustrationMV4" src={TicketAsset} alt="Illutro" />
      </div>
      <div className="contenedorTexto2">
        <div
          style={{
            maxWidth: "80%",
            textAlign: "justify",
            color: "#1976d2",
            fontWeight: 500,
          }}
        >
          <div className="MissionIcon">
            <div
              style={{
                backgroundColor: "#1976d2",
                padding: 30,
                borderRadius: 200,
                marginRight: "5%",
              }}
            >
              <div>
                <GoGoal className="iconInstagrams" />
              </div>
            </div>

            <div style={{ flex: 1, fontSize: 24 }}>
              <h2 className="textTitle2">Misión</h2>
              <p className="content">
                Proporcionar una solución para la administración de empresas ISP
                basada en la nube, que sea fácil de usar, escalable y
                personalizable.
              </p>
            </div>
          </div>
          <div className="MissionIcon">
            <div
              style={{
                backgroundColor: "#90caf9",
                padding: 30,
                borderRadius: 200,
                marginRight: "5%",
              }}
            >
              <div>
                <FaRegLightbulb className="iconInstagrams2" />
              </div>
            </div>

            <div style={{ flex: 1, fontSize: 24 }}>
              <h2 className="textTitle2">Visión</h2>
              <p className="content">
                Convertirnos en el proveedor líder de soluciones de
                administración de ISP basadas en la nube en el estado
                venezolano.
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export { MissionVision };
