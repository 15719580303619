import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import "./CobertureS.css";
import OLTManager from "../../assets/OLTManager.png";
import Finanzas from "../../assets/Finanzas.png";
import TicketsComunicación from "../../assets/Tickets&Comunicación.png";
import Instalaciones from "../../assets/Instalaciones.png";

class App extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      appendDots: (dots) => {
        return (
          <MagicSliderDots
            dots={dots}
            numDotsToShow={4}
            dotWidth={30}
            className="elementCarousel"
          />
        );
      },
    };

    return (
      <Slider
        {...settings}
        style={{
          width: "100%",
          height: 600,
          marginBottom: 100,
        }}
      >
        <div className="componenteCarousel">
          <div className="elementCarousel">
            <img className="OLTManager" src={OLTManager} alt="OLTM" />
            <div style={{ marginLeft: 20 }}>
              <span className="titleModulo">OLT Manager</span>
              <ul className="Caracteristicas">
                <li style={{ marginBottom: 10 }}>
                  Integración con MikroTik, VSOL, ZTE.
                </li>
                <li style={{ marginBottom: 10 }}>
                  Adaptación y aprovisionamiento automático de ONU’s y OLT’s.
                </li>
                <li style={{ marginBottom: 10 }}>
                  Historial, análisis y monitorización del tráfico y consumo de
                  la red.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="elementCarousel">
            <img className="Finanzas" src={Finanzas} alt="Finanzas" />
            <div style={{ marginLeft: 20 }}>
              <span className="titleModulo">Finanzas</span>
              <ul className="Caracteristicas">
                <li style={{ marginBottom: 10 }}>Facturación digital.</li>
                <li style={{ marginBottom: 10 }}>
                  Confirmación automatizada de transferencias.
                </li>
                <li style={{ marginBottom: 10 }}>Balance tributario.</li>
                <li style={{ marginBottom: 10 }}>Estadísticas de ingresos.</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="elementCarousel">
            <img className="TYC" src={TicketsComunicación} alt="Finanzas" />
            <div style={{ marginLeft: 20 }}>
              <span className="titleModulo">Tickets & Comunicación</span>
              <ul className="Caracteristicas">
                <li style={{ marginBottom: 10 }}>
                  Canal de chat con el cliente.
                </li>
                <li style={{ marginBottom: 10 }}>Seguimiento de tickets.</li>
                <li style={{ marginBottom: 10 }}>
                  Integración para mensajería masiva SMS, Whatsapp (QR).
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="elementCarousel">
            <img className="Instalaciones" src={Instalaciones} alt="Finanzas" />
            <div style={{ marginLeft: 20 }}>
              <span className="titleModulo">Instalaciones</span>
              <ul className="Caracteristicas">
                <li style={{ marginBottom: 10 }}>
                  Captación de datos, asignación de equipos.
                </li>
                <li style={{ marginBottom: 10 }}>
                  Delegación, gestión de cuadrillas y técnicos.
                </li>
                <li style={{ marginBottom: 10 }}>
                  Registro, auditoria y documentación de las instalaciones.
                </li>
                <li style={{ marginBottom: 10 }}>
                  App movil para técnicos e instaladores.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Slider>
    );
  }
}
export { App };
