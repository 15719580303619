import React, { useState } from "react";
import axios from "axios";
import "./App.css";
import { BsInstagram } from "react-icons/bs";
import headerImage from "./assets/headerImage.svg";
import { InfoGeneral } from "./InfoGeneral/InfoGeneral";
import { MissionVision } from "./Mission&Vision/Mission&Vision";
import { VideoComponent } from "./Videos/VideoComponent";
import { Coberture } from "./Videos/Coberture";
import IconWhite from "./assets/IconWhite.png";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiMenuAlt2 } from "react-icons/hi";
import { RiCloseLine } from "react-icons/ri";

function App() {
  const [razonSocial, setRazonSocial] = useState("");
  const [Direccion, setDireccion] = useState("");
  const [responsable, setResponsable] = useState("");
  const [telefono1, setTelefono1] = useState("");
  const [telefono2, setTelefono2] = useState("");
  const [email, setEmail] = useState("");
  const [facturacion, setFacturacion] = useState("");
  const [comentario, setComentario] = useState("");
  const [modal, setModal] = useState(false);

  const Not1 = () => {
    toast.success(
      `Formulario enviado con Exito, ¡gracias por preferir Rubpi!`,
      {
        // Set to 15sec
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
      }
    );
  };

  const Not2 = () => {
    toast.error(`Por favor, rellene el formulario para continuar.`, {
      // Set to 15sec
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2500,
    });
  };

  const Not3 = () => {
    toast.error(`Algo ha salido mal, intente de nuevo mas tarde.`, {
      // Set to 15sec
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2500,
    });
  };

  const enviarDatos = async () => {
    try {
      const respuesta = await axios.post("https://rubpi.com/api/formulario", {
        // Tus datos aquí
        razon_social: razonSocial,
        direccion: Direccion,
        responsable: responsable,
        telefono1: telefono1,
        telefono2: telefono2,
        correo: email,
        tipo_factiuracion: facturacion,
        comentario: comentario,
      });

      if (respuesta.status === 200) {
        Not1();
        resetStates();
      }
    } catch (error) {
      console.log(error.status);
      navigator.clipboard.writeText(error);
      Not3();
    }
  };

  const resetStates = () => {
    setRazonSocial("");
    setDireccion("");
    setResponsable("");
    setTelefono1("");
    setTelefono2("");
    setEmail("");
    setFacturacion("");
    setComentario("");
  };

  return (
    <div className="App">
      <ToastContainer />
      <div className="background-Container">
        <nav className="navBar">
          <div className="container-navBar">
            <img
              className="imageHeader"
              src="https://intranet.fibra360.net/api/image/logo_in"
              alt="aquiVaUnaImagen"
            />
            {modal ? (
              <RiCloseLine
                class="hamburger"
                onClick={() => {
                  setModal(false);
                }}
              />
            ) : (
              <HiMenuAlt2
                class="hamburger"
                onClick={() => {
                  setModal(true);
                }}
              />
            )}
            <ul className="navigatorNav">
              <a href="#infoGeneral">Inicio</a>
              <a href="#Modulos">Modulos</a>
              <a href="#Form">Contactenos</a>
              <a
                style={{
                  padding: 13,
                  backgroundColor: "#90caf9",
                  display: "flex",
                  borderRadius: 200,
                  marginRight: 0,
                }}
                href="https://www.instagram.com/rubpi.ve/"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="iconInstagram" />
              </a>
            </ul>
          </div>
        </nav>
        {modal ? (
          <modal className="ModalStyle">
            <ul className="menuResponsive">
              <a href="#infoGeneral">Inicio</a>
              <a href="#Modulos">Modulos</a>
              <a href="#Form">Contactenos</a>
              <a
                href="https://www.instagram.com/rubpi.ve/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </ul>
          </modal>
        ) : null}
        <header className="App-header">
          <div className="display">
            <section className="headerDistribution">
              <span className="textSlogan">
                Programa de Integración Continua
              </span>
              <p id="buttonStar">
                Impulsando la Eficiencia en la Gestión de Servicios y Clientes.
              </p>
            </section>
            <section className="headerDistribution2">
              <img
                className="illustrationHeader"
                src={headerImage}
                alt="Illutro"
              />
            </section>
          </div>
        </header>
      </div>
      <div className="separatorD"></div>
      <span className="subtitleD" id="infoGeneral">
        ¡Evoluciona en la manera de gestionar tu servicio!
      </span>
      <section>
        <InfoGeneral />
        <MissionVision />
        <VideoComponent />
        <Coberture />
      </section>
      <div className="background-Container" id="Form">
        <div className="separatorE"></div>

        <div className="element">
          <div className="element-left">
            <form
              className="formulario"
              onSubmit={(e) => {
                e.preventDefault();
                if (
                  !razonSocial ||
                  !Direccion ||
                  !responsable ||
                  !telefono1 ||
                  !telefono2 ||
                  !email ||
                  !facturacion ||
                  !comentario
                ) {
                  Not2();
                } else {
                  enviarDatos();
                }
              }}
            >
              <h4 className="title">Formulario de Pre-Registro</h4>
              <p>Razón Social:</p>
              <input
                type="text"
                value={razonSocial}
                onChange={(text) => {
                  setRazonSocial(text.target.value);
                }}
              />
              <p>Dirección:</p>
              <input
                type="text"
                value={Direccion}
                onChange={(text) => {
                  setDireccion(text.target.value);
                }}
              />
              <p>Responsable:</p>
              <input
                type="text"
                value={responsable}
                onChange={(text) => {
                  setResponsable(text.target.value);
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flex: 1, marginRight: 15 }}>
                  <p>Telefono 1:</p>
                  <input
                    type="text"
                    value={telefono1}
                    onChange={(number) => {
                      setTelefono1(number.target.value.trim());
                    }}
                  />
                </div>
                <div style={{ flex: 1, marginLeft: 15 }}>
                  <p>Telefono 2:</p>
                  <input
                    type="text"
                    value={telefono2}
                    onChange={(number) => {
                      setTelefono2(number.target.value.trim());
                    }}
                  />
                </div>
              </div>
              <p>E-mail:</p>
              <input
                type="email"
                value={email}
                onChange={(text) => {
                  setEmail(text.target.value.trim());
                }}
                required
              />
              <p>Tipo de Facturación:</p>
              <select
                className="selector"
                value={facturacion}
                onChange={(event) => {
                  setFacturacion(event.target.value);
                }}
              >
                <option value="">Selecciona una opción</option>
                <option value="FISICA">FISICA</option>
                <option value="DIGITAL">DIGITAL</option>
              </select>
              <p
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  color: "grey",
                  fontWeight: 400,
                }}
              >
                Indique el tipo de facturacion FISICA / DIGITAL
              </p>
              <p>Comentario:</p>
              <textarea
                id="comentario"
                rows="4"
                cols="50"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  borderColor: "#1976d2",
                  borderStyle: "solid",
                  borderWidth: "1px",
                }}
                value={comentario}
                onChange={(text) => {
                  setComentario(text.target.value);
                }}
              ></textarea>

              <button type="submit">Enviar Formulario</button>
            </form>
          </div>
          <div className="element-right">
            <img className="iconRubpi-white" src={IconWhite} alt="OLTM" />
          </div>
        </div>
        <footer className="footcopy">
          <p
            style={{
              fontSize: 35,
              fontWeight: "bold",
              color: "#ffff",
            }}
          >
            rubpi.com
          </p>
          <div
            style={{
              width: "30%",
              height: 1,
              backgroundColor: "#ffff",
              marginTop: 10,
            }}
          ></div>
          <p style={{ color: "#ffff" }}>Copyright © 2023</p>
          <p style={{ color: "#ffff" }}>Todos los derechos reservados</p>

          <p style={{ color: "#ffff", fontWeight: "bold", fontSize: 20 }}>
            LUMINICA PUBLICIDAD,C.A
          </p>
          <p style={{ color: "#ffff" }}>J-50251834-7</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
